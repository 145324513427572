<svelte:options accessors={true}/>

<script>
import Player from '@vimeo/player';
import { onMount, onDestroy, createEventDispatcher } from 'svelte';

export let videoId;
export let loop       = false;
export let muted      = false;
export let autoplay   = false;
export let controls   = false;
export let background = false;
export let responsive = true;
export let aspect     = 16/9;

export let poster = null;

const dispatch = createEventDispatcher();

const eventsToDispatch = "play pause ended timeupdate progress seeked loaded texttrackchange cuechange cuepoint volumechange error".split(' ');

let ready = false;
let vimeo;
let player;
let backgroundUrl;

$: loadVideo(videoId)
$: backgroundUrl = poster && poster.url || "";


// Helpers

function loadVideo(id) {
  if (!player) return
  return player.loadVideo(id)
}

function setEvents() {
  player.ready()
    .then(() => { ready = false; dispatch('ready', { player }) })
    .catch(error => dispatch('error', { error, player }))
  eventsToDispatch.forEach(event => dispatchOnPlayerEvent(event))
}

function dispatchOnPlayerEvent (event) {
  player.on(event, data => dispatch(event, { data, player }))
}


// Hooks

let element;

onMount(async () => {
 
  if (controls) {
    aspect = 21/9
  }
  vimeo = new Player(element, {
    id: videoId,
    loop,
    muted,
    controls,
    autoplay,
    responsive,
    background,
    autopause: false
  })

  player = new Proxy(vimeo, {
    get: (target, prop) => {
      var value = target[prop];
      return typeof value == 'function' ? value.bind(target) : value;
    }
  });

  setEvents()
});

onDestroy(() => {
  if (player) player.unload();
})

function aspectWatch (node) {
  function updateAspect (target, parent, eventType) {
    let factor = 1;

    if (target?.offsetWidth > parent?.offsetWidth) {
      factor = parent.offsetWidth / target.offsetWidth;
    }
    else if (target?.offsetHeight > parent?.offsetHeight) {
      factor = parent.offsetHeight / target.offsetHeight;
    }

    target.style.transform = `scale(${factor})`;

    //console.info(`VimeoPlayer::${eventType} - factor is ${factor}`);
  }

  // Wait until dom is updated, then measure the difference
  new MutationObserver((entries) => {
    for (let entry of entries) {
      updateAspect(entry.target, entry.target.parentNode, 'mutate');
    }
  }).observe(node, { childList: true, subtree: true });

  new ResizeObserver((entries) => {
    for (let entry of entries) {
      updateAspect(entry.target, entry.target.parentNode, 'resize');
    }
  }).observe(node);

}

</script>


<div class="VimeoPlayer"
  class:event-overlay={!controls}
  class:ready
  class:landscape={ aspect > 1 } class:portrait={ aspect <= 1 }
  bind:this={element}
  use:aspectWatch
  style="background-image: url('{ backgroundUrl }'); --aspect: { aspect }" />


<style lang="scss">
  .VimeoPlayer {
    position: relative;
    background-size: cover;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;

    &.portrait { height: 100%; }
    &.landscape { width: 100%; }

    max-height: 100%;
 
    aspect-ratio: var(--aspect);
    

   

    // Puts an invisible overly over vimeo's iframe if the user doesn't actually need
    // to interact with it (ie: 'controls' was disabled). Otherwise scroll events
    // mysteriously get eaten and it interrupts page scrolling. Blame Vimeo I think
    &.event-overlay {
      &:before {
        content: "";
        display: block;
        padding-top: calc(100% / var(--aspect));
        position: absolute;
        inset: 0;
        z-index: 1;
      }
    }

    :global(iframe) {
      position: absolute;
      top:      50%;
      left:     50%;
      width:    100% !important;
      height:   100% !important;
    }
  }
</style>
